<template>
  <article class="c-article">
    <header class="c-article__header">
      <div class="l-container">
        <h1 class="c-article__title" v-t="'利用規約'" />
      </div>
    </header>
    <div class="c-article__content">
      <div class="l-container">
        <div class="content">
          <p class="c-article__planeText" v-t="this.portalId" />
          <CImage class="term-flow" :src="flowImageUrl" />
          <!-- <ul class="termLinkList">
            <li class="termLinks"
              v-if="this.$i18n.locale === 'ja'">
            <app-link
              class="termLink c-link"
              href="/terms/SLA.pdf"
              target="_blank"
              v-t="'SLA'"
            />
            </li>
            <li class="termLinks"
              v-if="this.$i18n.locale === 'en'">
            <app-link
              class="termLink c-link"
              href="/terms/SLA_en.pdf"
              target="_blank"
              v-t="'SLA'"
            />
          </li>
          <li class="termLinks"
            v-if="this.$i18n.locale === 'ja'">
            <app-link
              class="termLink c-link"
              href="/terms/Servicespec.pdf"
              target="_blank"
              v-t="'サービス仕様書'"
            />
          </li>
          <li class="termLinks"
            v-if="this.$i18n.locale === 'en'">
            <app-link
              class="termLink c-link"
              href="/terms/Servicespec_en.pdf"
              target="_blank"
              v-t="'サービス仕様書'"
            />
          </li>
        </ul> -->
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import CImage from '@/components/common/CImage.vue'
import { messages } from '@/assets/lang/terms'

export default {
  name: 'TermsPresentation',
  metaInfo: {
    htmlAttrs: {
      class: '-background-white',
    },
  },
  components: {
    CImage,
  },
  i18n: {
    messages,
  },
  computed: {
    siteName() {
      return this.$t(`${this.portalId}.おみせのトラスト`)
    },
    flowImageUrl() {
      let flowImageUrl
      switch (this.$i18n.locale) {
        case 'en':
          flowImageUrl = `/img/terms/flow_${this.portalId}_en.png`
      }
      // 英語情報が存在しない場合、日本語情報にフォールバック
      return flowImageUrl || `/img/terms/flow_${this.portalId}.png`
    },
  },
}
</script>

<style>
.term-flow {
  margin-top: 40px;
}
</style>
