<template>
  <div>
    <TermsPresentation />
  </div>
</template>

<script>
import { defaultLayoutViewMixin } from '@/mixins'
import TermsPresentation from '@/components/terms/TermsPresentation.vue'

export default {
  name: 'Terms',
  mixins: [defaultLayoutViewMixin],
  components: {
    TermsPresentation,
  },
  metaInfo() {
    return {
      title: this.$t('利用規約'),
    }
  },
}
</script>
