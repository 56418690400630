<template>
  <figure v-if="computedClass.length === 0" class="c-image">
    <img :src="computedSrc" :alt="alt" />
  </figure>
  <div
    v-else
    :class="['c-image', computedClass]"
    :style="`background-image: url(${computedSrc})`"
  />
</template>

<script>
const sizes = [
  'full',
  '1x1',
  'square',
  '16x16',
  '24x24',
  '32x32',
  '48x48',
  '64x64',
  '96x96',
  '128x128',
  '5p4',
  '4p3',
  '3p2',
  '5p3',
  '5p2',
  '16p9',
  '2p1',
  '3p1',
  '4p5',
  '3p4',
  '2p3',
  '3p5',
  '2p5',
  '9p16',
  '1p2',
  '1p3',
]

const NoImageMap = {
  shops: require('@/assets/img/common/visual_no_image.png'),
  schools: require('@/assets/img/common/visual_no_image_schools.png'),
  hospitals: require('@/assets/img/common/visual_no_image_hospitals.png'),
}

export default {
  name: 'CImage',
  props: {
    src: String,
    alt: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      validator: (value) => sizes.includes(value),
    },
    noImage: {
      type: Boolean,
    },
    contain: {
      type: Boolean,
    },
    cover: {
      type: Boolean,
    },
  },
  computed: {
    computedSrc() {
      return this.noImage || !this.src
        ? NoImageMap[this.portalId] || NoImageMap.shops
        : this.src
    },
    computedClass() {
      const result = []

      if (this.size) result.push(`c-image--${this.size}`)
      if (this.contain) result.push(`c-image--contain`)
      if (this.cover) result.push(`c-image--cover`)

      return result
    },
  },
}
</script>
